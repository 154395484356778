<template>
  <div>
    <crud-table
      ref="crudTable"
      :list-items="listItems"
      :is-editable="false"
      heading="Regions"
      row-click-path="/tables/regions"
      endpoint="regions"
      query-string="&language=ALL"
      @create="createRegion()"
      @edit="data => editRegion(data)"
      @delete="id => deleteRegion(id)"
      @restore="id => restoreRegion(id)"
    />

    <edit-dialog
      :show.sync="editDialog.display"
      :max-width="'600'"
      @dialogConfirm="editDialog.saveFunction">
      <template>
        <v-container grid-list-xl>
          <v-layout
            row
            wrap>
            <v-flex
              xs12
              sm6>
              <v-text-field
                v-model="editDialog.data.name"
                :max-length="TEXT_FIELD_MAX_LENGTH"
                :rules="[v => Boolean(v) || 'Please provide a name']"
                label="Name"
              />
            </v-flex>
            <v-flex xs-12>
              <v-combobox
                v-model="cities"
                :search-input.sync="search"
                multiple
                label="Cities"
                append-icon
                chips
                deletable-chips
                class="tag-input"
                @keyup.tab="updateTags"
                @paste="updateTags"/>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </edit-dialog>
  </div>
</template>

<script>
import CrudTable from '../../../modules/admin/CrudTable.vue'
import Request from '../../../helpers/request'
import { TEXT_FIELD_MAX_LENGTH, LANGUAGE_TYPES } from '../../../constants/common'

export default {
  components: { CrudTable },
  mixins: [Request],
  data () {
    return {
      brandList: [],
      // typeList: POST_TYPES,
      languageList: LANGUAGE_TYPES,
      TEXT_FIELD_MAX_LENGTH,
      listItems: [
        { text: 'ID', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Created at', value: 'createdAt' },
        { text: 'Modified at', value: 'modifiedAt' },
        { text: '', value: null }
      ],
      loading: true,
      data: [],
      editDialog: {
        display: false,
        saveFunction: () => {},
        data: {}
      },
      cities: [],
      search: ''
    }
  },
  methods: {
    createRegion () {
      this.editDialog.display = true
      this.editDialog.data = { }

      this.editDialog.type = 'create'

      this.editDialog.saveFunction = () => {
        let body = {
          data: {
            name: this.editDialog.data.name,
            cities: this.cities
          }
        }

        this.request('POST', `/regions`, body, ({ data }) => {
          this.editDialog.display = false
          this.$refs.crudTable.requestData()
          this.$notify({
            group: 'topRight',
            type: 'success',
            text: 'Region is created'
          })

          this.editDialog.display = false
          this.$refs.crudTable.requestData()
        })
      }
    },
    deleteRegion (id) {
      this.request('DELETE', `/regions/${id}`, {}, ({ data }) => {
        this.$refs.crudTable.requestData()
        this.$notify({
          group: 'topRight',
          type: 'warning',
          text: 'Region is deleted'
        })
      })
    },
    restoreRegion (id) {
      this.request('PATCH', `/trash/un-delete/region/${id}`, {}, ({ data }) => {
        this.$refs.crudTable.requestData({ deleted: 1 })
        this.$notify({
          group: 'topRight',
          type: 'warning',
          text: 'Region is restored'
        })
      })
    },
    editRegion (data) {
      this.editDialog.display = true
      this.editDialog.data = this.gFunc.deepCopy(data)
      this.editDialog.type = 'edit'

      this.editDialog.saveFunction = () => {
        let body = {
          data: {
            name: this.editDialog.data.name
          }
        }

        this.request('PATCH', `/regions/${data.id}`, body, ({ data }) => {
          this.$notify({
            group: 'topRight',
            type: 'success',
            text: 'Region is updated'
          })

          this.editDialog.display = false
          this.$refs.crudTable.requestData()
        })
      }
    },
    updateTags () {
      this.$nextTick(() => {
        this.cities && this.cities.push(...this.search.split(','))
        this.$nextTick(() => {
          this.search = ''
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .tag-input span.chip {
    background-color: #1976d2;
    color: #fff;
    font-size: 1em;
  }

  .tag-input span.v-chip {
    background-color: #1976d2;
    color: #fff;
    font-size:1em;
    padding-left:7px;
  }

  .tag-input span.v-chip {
    &:before {
      content: "label";
      font-size: 20px;
      line-height: 1;
      display: inline-block;
      white-space: nowrap;
    }
  }
</style>
